<template>
  
  <div class="footer">
    <hr>
    <div class="spacer s30"></div>
      From the creators of <a target="_blank" href="https://offshoresms.com.au">OffshoreSMS</a><br>&copy; Xmarine 2021
  </div>


</template>




<script>
  
  

  export default {

    data() {
      return {
        
      }
    },

  }
  

</script>



<style lang="scss" scoped>
  
  .footer {
    text-align: center;
    margin-top: 5rem;
    padding: 5rem 2rem 8rem;
    background: #fff;
    margin-bottom: 6rem;
  }


</style>